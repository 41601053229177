import { HeadThree } from "../../components/Search/components/HeadThree";
import "./style.scss";

export const AboutPage = () => {
  return (
    <section className="about-page">
      <div className="container">
        <HeadThree />
        <h2>Sobre nosotros</h2>
        <p>
          is a Mexican drugstore chain, that was founded in 1942[1] in
          Guadalajara, Jalisco. In 1962 it began an expansion program,[1] and
          currently has more than 1725 drugstores in 19 States. It is one of
          only 3 businesses from Jalisco to be listed on the Mexican stock
          market. The name of this business in the Mexican Stock Market is
          Fragua.
        </p>
        <img src="/image/about-image.jpeg" alt="" />
      </div>
    </section>
  );
};
