import { useForm } from "react-hook-form";
import "./style.scss";
import { BasketStore } from "../../../../Store/BasketStore";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "../../../../navigation/routeNames";
import { observer } from "mobx-react-lite";

export const Form = observer(() => {
  const navigate = useNavigate();
  const basketLength = BasketStore.getAll().length;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const sendForm = async (data) => {
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    //TODO: отправлять поле title в таблицу.
    await fetch(
      "https://script.google.com/macros/s/AKfycbzPS7U-Kg3RvRMNO22vP6KtReh6BF5AtO3FRkNZDN4J0A4O_8ZTg7S4L4nGgg6wdS2q/exec",
      {
        method: "POST",
        body: formDatab,
      }
    )
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = async (data) => {
    // полуили корзину
    const basket = BasketStore.getAll();
    let groupByChannel = {};
    // группиируем в объект по channel key
    basket.forEach((element) => {
      groupByChannel[element.channel] = groupByChannel[element.channel] || [];
      groupByChannel[element.channel].push(element);
    });
    // получаем ключи из группы каналов (channel)
    const objectKeys = Object.keys(groupByChannel);
    // формируем поле cart
    const cartData = [];
    objectKeys.forEach((element) => {
      cartData.push({
        s: groupByChannel[element][0].partner,
        c: groupByChannel[element].length,
        z: { referer: "https://facebook.com/", channel: element },
      });
    });

    await fetch(
      "https://hard-good.fun/api/USywFRTQ31LP74Q4LUPfn8vbTdob/checkout",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: data.name,
          phone: data.telephone,
          email: data.email,
          cart: cartData,
        }),
      }
    )
      .then((r) => {
        r.json();
        sendForm(data);
        reset();
        navigate(routeNames.thanks, { replace: true });
        BasketStore.deleteAll();
      })
      .catch((error) => console.error("error", error));
  };

  return basketLength > 0 ? (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      <span>realización del pedido</span>
      <input
        {...register("name", {
          required: true,
          maxLength: 20,
        })}
        placeholder="Nombre"
        className={errors.name ? "input-error" : ""}
        name="name"
      />
      <input
        type="number"
        {...register("telephone", {
          required: true,
          maxLength: 11,
        })}
        placeholder="Número de teléfono"
        className={errors.telephone ? "input-error" : ""}
        name="telephone"
      />
      <input
        {...register("email", {
          required: true,
          maxLength: 50,
        })}
        placeholder="Сorreo electrónico"
        className={errors.email ? "input-error" : ""}
        name="email"
      />
      <button type="submit" className="btn-reset">
        comprar
      </button>
    </form>
  ) : (
    <div className="carrito">
      <img src="image/carrito.png" alt="" />
      <span className="message">El carrito esta vacío</span>
      <div>
        <Link to={"/"}>agregar productos</Link>
      </div>
    </div>
  );
});
