import { useEffect } from "react";
import { Hero } from "../../components/Hero";
import data from "../../data/products.json";
import "./style.scss";
import { BasketStore } from "../../Store/BasketStore";
import { useState } from "react";
import { Welcome } from "../../components/Welcome";
import { WelcomeStore } from "../../Store/WelcomeStore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { Link } from "react-router-dom";
import { routeNames } from "../../navigation/routeNames";
import { observer } from "mobx-react-lite";
import { Plus } from "../../components/Plus";

export const MainPage = observer(() => {
  const sliderData = [
    data[0],
    data[1],
    data[2],
    data[3],
    data[4],
    data[5],
    data[6],
    data[49],
    data[53],
    data[12],
    data[62],
    data[11],
    data[54],
  ];
  const [welcome, hasWelcome] = useState(WelcomeStore.get() || false);

  useEffect(() => {
    setTimeout(() => {
      hasWelcome(true);
      WelcomeStore.set(true);
    }, 1500);
  }, []);
  return !welcome ? (
    <Welcome />
  ) : (
    <div>
      <Hero />
      <Swiper
        spaceBetween={10}
        modules={[Autoplay]}
        pagination={{ clickable: true }}
        slidesPerView={1}
        autoplay={{
          delay: 1500,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        loop
      >
        <SwiperSlide>
          <Link to={`${routeNames.categories}/Diolix`} state={data[49]}>
            <img src="image/oferta1.png" alt="category" />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`${routeNames.categories}/Hialuronika`} state={data[53]}>
            <img src="image/oferta2.png" alt="category" />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`${routeNames.categories}/Liversim`} state={data[12]}>
            <img src="image/oferta3.png" alt="category" />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`${routeNames.categories}/Motion Energy`} state={data[62]}>
            <img src="image/oferta4.png" alt="category" />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`${routeNames.categories}/Oculax`} state={data[11]}>
            <img src="image/oferta5.png" alt="category" />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to={`${routeNames.categories}/Veniselle`} state={data[54]}>
            <img src="image/oferta6.png" alt="category" />
          </Link>
        </SwiperSlide>
      </Swiper>

      <ul className="product-slider list-reset">
        <Swiper
          spaceBetween={10}
          modules={[Autoplay]}
          pagination={{ clickable: true }}
          slidesPerView={3}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          loop
        >
          {sliderData.map((el) => (
            <SwiperSlide key={Math.random()} className="list-slide">
              <li key={el.id}>
                <div className="img-box">
                  <Link to={el.title} state={el}>
                    <img src={`/image/${el.img}`} alt="rr" />
                  </Link>
                </div>
                <span className="title">{el.title}</span>
                <span className="subtitle">{el.subDescr}</span>
                <span className="oldprice">{el.oldPrice} MXN</span>
                <span className="newprice">{el.price} MXN</span>
                <button
                  className="btn-reset"
                  onClick={() => {
                    BasketStore.add(el);
                  }}
                >
                  AGREGAR
                </button>
              </li>
            </SwiperSlide>
          ))}
        </Swiper>
      </ul>
      <Plus />
    </div>
  );
});
