import "./style.scss";

export const OrderPage = () => {
  return (
    <section className="order-page">
      <div className="container">
        <img src="/image/order.png" alt="" />
      </div>
    </section>
  );
};
