import "./style.scss";

export const HeadOne = () => {
  return (
    <div>
      <a href="/">
        <img className="logo" src="/logo.png" alt="" />
      </a>
      <p>Vitaminas, Suplementos & Productos Naturales para la Salud</p>
      <img className="head-1" src="/head-1.png" alt="" />
    </div>
  );
};
