import { HeadThree } from "../../components/Search/components/HeadThree";
import "./style.scss";

export const AddressPage = () => {
  return (
    <section className="about-page">
      <div className="container">
        <HeadThree />
        <h2>Encuentra Tu SuperFarmacia </h2>
        <div className="icon-address">
          <img src="/image/icon-address.png" alt="" />
          <p>
            Av. de las Américas #1254 - Int. UP6, P2Col. Country
            ClubGuadalajara, JaliscoC.P. 44610
          </p>
        </div>
        <img className="address-img" src="/image/address-img.jpg" alt="" />
      </div>
    </section>
  );
};
