import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./style.scss";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BasketStore } from "../../Store/BasketStore";
import RewiewsData from "../../data/reviews.json";
import RewiewCard from "./rewiew";
import { routeNames } from "../../navigation/routeNames";
import { observer } from "mobx-react-lite";
import products from "../../data/products.json";

export const ProductPage = observer(() => {
  const [data, setData] = useState({});
  const { state, pathname } = useLocation();
  const stateByWindowLocation = products.find(
    (el) => el.id == pathname.split("/")[2]
  );
  useEffect(() => {
    setData(state || stateByWindowLocation);
  }, [state, stateByWindowLocation]);

  const {
    title,
    subDescr,
    oldPrice,
    price,
    descr,
    img,
    id,
    notAvailable,
    avatar,
    docName,
    docDescription,
    recomendation,
  } = data;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();

  const sendForm = async (data) => {
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);
    //TODO: отправлять поле title в таблицу.
    await fetch(
      "https://script.google.com/macros/s/AKfycbzPS7U-Kg3RvRMNO22vP6KtReh6BF5AtO3FRkNZDN4J0A4O_8ZTg7S4L4nGgg6wdS2q/exec",
      {
        method: "POST",
        body: formDatab,
      }
    )
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onSubmit = async (data) => {
    await fetch(
      "https://hard-good.fun/api/USywFRTQ31LP74Q4LUPfn8vbTdob/checkout",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: data.name,
          phone: data.telephone,
          email: data.email,
          cart: [
            {
              s: "KMA",
              c: 1,
              z: { referer: "https://facebook.com/", channel: "test" },
            },
          ],
        }),
      }
    )
      .then((r) => {
        r.json();
        sendForm(data);
        reset();
        navigate(routeNames.main, { replace: true });
      })
      .catch((error) => console.error("error", error));
  };

  const [showSendRewiewModal, setShowSendRewiewModal] = useState(false);
  const [showSendConsultationModal, setShowSendConsultationModal] =
    useState(false);
  const [showSuccessRewiewModal, setShowSuccessRewiewModal] = useState(false);
  const formRef = useRef(null);
  const itemInBasket = (itemID) =>
    BasketStore.array.some((item) => item.id === itemID);
  return (
    <section className="product-section">
      <div className="container">
        {
          <div className="card">
            <div className="image-box">
              <img src={`/image/${img}`} alt="" />
            </div>
            <div className="wrapper-back">
              <div className="wrapper-box">
                <div className="left-box">
                  <img src="/image/logo2.png" alt="" />
                  <div className="title-box">
                    <h2>{title}</h2>
                    <span>{subDescr}</span>
                  </div>
                </div>
                <div className="right-box">
                  <div className="oldprice">{oldPrice} MXN</div>
                  <div className="newprice">{price} MXN</div>
                </div>
              </div>
              {avatar ? (
                <div className="recomendation-box">
                  <span className="recomendation">{recomendation}</span>
                  <div className="doctor">
                    <img src={`/image/${avatar}`} alt="" />
                    <div className="doctor-text">
                      <span className="doctor-name">{docName}</span>
                      <span className="doctor-descr">{docDescription}</span>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="btn-box">
                <button
                  className="btn-reset btn"
                  onClick={() => {
                    BasketStore.add(data);
                  }}
                  disabled={itemInBasket(id) || notAvailable}
                >
                  {notAvailable
                    ? "notifiqueme cuando esté disponible"
                    : itemInBasket(id)
                    ? "en la cesta"
                    : "AGREGAR"}
                </button>
                <button
                  onClick={() => setShowSendConsultationModal(true)}
                  className="btn btn-consultation btn-reset"
                >
                  Obtenga una consulta gratuita
                </button>
              </div>

              <div className="descr-box">
                <span>description</span>
                <p className="descr">{descr}</p>
                <span>optiones</span>
                <button
                  onClick={() => setShowSendRewiewModal(true)}
                  className="btn-reset btn-rewiew"
                >
                  escribir una opinión
                </button>
                {RewiewsData.map(
                  (rewiewData, index) =>
                    rewiewData.id === id && (
                      <RewiewCard rewiew={rewiewData} key={index} />
                    )
                )}
              </div>
              {showSendConsultationModal ? (
                <div className="modal-send-rewiew">
                  <div
                    //  Закрыттиие модального окна по нажатию на overlay
                    onClick={(e) => {
                      if (e.target.className === "modal-overlay") {
                        setShowSendConsultationModal(false);
                      }
                    }}
                    className="modal-overlay"
                  >
                    <div className="modal-form-consultation">
                      <span className="consultation-title-form">
                        ¿LE GUSTA ESTA OFERTA?
                      </span>
                      <p className="consultation-descr">
                        ¡Le diremos todo sobre el producto, ofrecemos las
                        mejores condiciones y ponerse al corriente de las
                        ofertas promocionales apropiadas!{" "}
                      </p>

                      <form onSubmit={handleSubmit(onSubmit)} className="form">
                        <input
                          {...register("name", {
                            required: true,
                            maxLength: 20,
                          })}
                          placeholder="Nombre"
                          className={errors.name ? "input-error" : ""}
                          name="name"
                        />
                        <input
                          type="number"
                          {...register("telephone", {
                            required: true,
                            maxLength: 11,
                          })}
                          placeholder="Número de teléfono"
                          className={errors.telephone ? "input-error" : ""}
                          name="telephone"
                        />
                        <input
                          {...register("email", {
                            required: true,
                            maxLength: 50,
                          })}
                          placeholder="Сorreo electrónico"
                          className={errors.email ? "input-error" : ""}
                          name="email"
                        />
                        <button type="submit" className="btn-reset">
                          Enviar
                        </button>
                        <p className="consultation-descr">
                          El operador le llamará dentro de 15-30 minutos
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}
              {showSendRewiewModal ? (
                <div className="modal-send-rewiew">
                  <div
                    //  Закрыттиие модального окна по нажатию на overlay
                    onClick={(e) => {
                      if (e.target.className === "modal-overlay") {
                        setShowSendRewiewModal(false);
                      }
                    }}
                    className="modal-overlay"
                  >
                    <div className="modal-form">
                      <span>nueva opinion</span>

                      <form ref={formRef} action="#" className="form">
                        <textarea
                          type="text"
                          name="message"
                          placeholder="nueva opinion.."
                          required
                        />
                        <label>
                          <input type="text" required name="name" />
                          <span>Nombre</span>
                        </label>
                        <label>
                          <input type="tel" required />
                          <span>Edad</span>
                        </label>
                        <label>
                          <input type="email" required name="email" />
                          <span>Сorreo electrónico</span>
                        </label>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            console.log(e.target.validity);
                            // Используем useRef для того , чтобы проверить заполнены ли поля в форме
                            // Сделано , чтобв проверять у инпут родной required
                            const isValidForm = formRef.current.checkValidity();
                            // TODO: Получииьт все поля формы для оттправки

                            if (isValidForm) {
                              setShowSuccessRewiewModal(true);
                              setShowSendRewiewModal(false);
                            }
                          }}
                          type="submit"
                          className="btn-reset"
                        >
                          publicar
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}
              {showSuccessRewiewModal ? (
                <div className="modal-success-send-rewiew">
                  <div
                    onClick={(e) => {
                      if (e.target.className === "modal-overlay") {
                        setShowSendRewiewModal(false);
                      }
                    }}
                    className="modal-overlay-answer"
                  >
                    <div className="modal-answer">
                      <span>su comentario ha sido enviado a moderación</span>
                      <button
                        onClick={() => setShowSuccessRewiewModal(false)}
                        className="btn-reset"
                      >
                        atrás
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        }
      </div>
      {avatar ? (
        <div className="popup">
          <span>APRESÚRATE A PEDIR HASTA EL FINAL DE LA OFERTA:</span>
          <div className="img-box">
            <img src="/image/gif.gif" alt="" />
          </div>
        </div>
      ) : null}
    </section>
  );
});
