import { Link } from "react-router-dom";
import products from "../../data/products.json";
import { SearchBox } from "../../components/Search";
import { BasketStore } from "../../Store/BasketStore";
import { HeadThree } from "../../components/Search/components/HeadThree";
import { useState } from "react";
import "./style.scss";
import { observer } from "mobx-react-lite";

const categories = [
  "todos los productos",
  "salud",
  "hipertension",
  "abandonar los malos hábitos",
  "adelgazar",
  "saludmasculina",
  "diabetes",
  "belleza",
  "articulacion",
  "rumor",
  "vision",
  "nuevo",
];

export const CategoriesPages = observer(() => {
  const itemInBasket = (itemID) =>
    BasketStore.array.some((item) => item.id === itemID);

  // Фильтрация продуктов по категориям
  const [type, setType] = useState("");

  const filter = (el) => {
    el === "todos los productos" ? setType("") : setType(el);
  };

  return (
    <section className="category-section">
      <div className="container">
        <HeadThree />
        <SearchBox />
        <ul className="list-reset categories-list">
          {categories.map((el) => {
            return (
              <li key={Math.random()}>
                <span
                  onClick={() => filter(el)}
                  style={{
                    backgroundColor: el === type ? "white" : "",
                    borderRadius: el === type ? "5px" : "",
                  }}
                >
                  {el}
                </span>
              </li>
            );
          })}
        </ul>
        <ul className="list-reset">
          {products.map((el) => {
            return (
              // При заходе на страницу
              type !== "" ? (
                // По условию типу
                type === el.type && (
                  <li key={el.id}>
                    <div className="img-box">
                      <Link to={el.title} state={el}>
                        <img src={`/image/${el.img}`} alt="product" />
                      </Link>
                    </div>

                    <div className="wrapper-box">
                      <h4>{el.title}</h4>

                      <span>{el.subDescr}</span>
                      <span className="oldprice">{el.oldPrice} MXN</span>
                      <span className="newprice">{el.price} MXN</span>
                      <button
                        onClick={() => {
                          BasketStore.add(el);
                        }}
                        className="btn-reset"
                        disabled={itemInBasket(el.id) || el.notAvailable}
                      >
                        {el.notAvailable
                          ? "notifiqueme cuando esté disponible"
                          : itemInBasket(el.id)
                          ? "en la cesta"
                          : "AGREGAR"}
                      </button>
                    </div>
                  </li>
                )
              ) : (
                // Все товары
                <li key={el.id}>
                  <div className="img-box">
                    <Link to={el.title} state={el}>
                      <img src={`/image/${el.img}`} alt="product" />
                    </Link>
                  </div>

                  <div className="wrapper-box">
                    <h4>{el.title}</h4>

                    <span>{el.subDescr}</span>
                    <span className="oldprice">{el.oldPrice} MXN</span>
                    <span className="newprice">{el.price} MXN</span>
                    <button
                      onClick={() => {
                        BasketStore.add(el);
                      }}
                      className="btn-reset"
                      disabled={itemInBasket(el.id) || el.notAvailable}
                    >
                      {el.notAvailable
                        ? "notifiqueme cuando esté disponible"
                        : itemInBasket(el.id)
                        ? "en la cesta"
                        : "AGREGAR"}
                    </button>
                  </div>
                </li>
              )
            );
          })}
        </ul>
      </div>
    </section>
  );
});
