import { Link } from "react-router-dom";
import "./style.scss";
import { routeNames } from "../../navigation/routeNames";
import { observer } from "mobx-react-lite";
import { BasketStore } from "../../Store/BasketStore";
export const Header = observer(() => {
  return (
    <header>
      <div className="container">
        <div className="nav">
          <Link replace to={routeNames.main} className="img-1">
            HOGAR
          </Link>
          <Link replace to={routeNames.categories} className="img-2">
            CATEGORÍAS
          </Link>
          <Link replace to={routeNames.basket} className="img-3">
            CARRITO
            <span>{BasketStore.array.length}</span>
          </Link>
          <Link replace to={routeNames.information} className="img-4">
            INFORMACIÒN
          </Link>
        </div>
      </div>
    </header>
  );
});
