import "./style.scss";

export const HeadThree = () => {
  return (
    <div>
      <a href="/">
        <img className="logo" src="/logo.png" alt="" />
      </a>
    </div>
  );
};
