import { makeAutoObservable } from "mobx";
class Basket {
  array = [];

  constructor() {
    makeAutoObservable(this);
    //  при инициализации Basket, в array передем состояние localstorage.
    this.array = JSON.parse(localStorage.getItem("BASKET")) || [];
  }
  add = (el) => {
    this.array.push(el);
    const basket = JSON.parse(localStorage.getItem("BASKET"));
    localStorage.setItem("BASKET", JSON.stringify([...(basket ?? []), el]));
  };
  remove = (el) => {
    const mobxIndex = this.array.findIndex((item) => item.id === el.id);
    this.array.splice(mobxIndex, 1);
    localStorage.setItem("BASKET", JSON.stringify(this.array));
  };
  getAll = () => {
    return this.array;
  };
  deleteAll = () => {
    this.array = [];
    localStorage.setItem("BASKET", "[]");
  };
  countProducts = () => {
    return Object.values(
      this.array.reduce((p, v) => {
        const old = p[v.title];
        if (!old) p[v.title] = { ...v, count: 1 };
        else if (old.sort > v.sort) p[v.title] = { ...v, count: old.count + 1 };
        else p[v.title].count++;
        return p;
      }, {})
    );
  };
  checkTotalPrice = () => {
    let total = 0;
    this.array.forEach((el) => {
      total += Number(el.price);
    });
    return total;
  };
}
export const BasketStore = new Basket();
