import "./style.scss";
import { BasketStore } from "../../Store/BasketStore";
import { Form } from "./components/Form";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

export const BasketPage = observer(() => {
  return (
    <div>
      <section className="basket-section">
        <div className="container">
          <ul className="list-reset">
            {BasketStore.countProducts().map((element) => {
              return (
                <li key={element.id}>
                  <div className="image-box">
                    <img src={`/image/${element.img}`} alt="product" />
                  </div>
                  <div className="wrapper-box">
                    <h4>{element.title}</h4>
                    <span className="descr">{element.subDescr}</span>
                    <div className="box">
                      <span className="price">{element.price} MXN</span>
                      <div className="counter">
                        <button
                          onClick={() => {
                            BasketStore.remove(element);
                          }}
                          className="btn-reset"
                        >
                          &minus;
                        </button>
                        <span>{element.count}</span>
                        <button
                          onClick={() => {
                            BasketStore.add(element);
                          }}
                          className="btn-reset"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
            {BasketStore.array.length > 0 && (
              <span className="total-price">
                importe total: {BasketStore.checkTotalPrice()}
              </span>
            )}

            <Form />
          </ul>
        </div>
      </section>
      <div className="modal">
        <div className="container">
          <h3>¡Gracias!</h3>
          <span>su pedido ha sido aceptado</span>
          <p>
            Nuestro responsable se pondrá en contacto con usted en breve para
            confirmar su pedido.{" "}
          </p>
          <Link to={"/"}>hogar</Link>
        </div>
      </div>
    </div>
  );
});
