import "./style.scss";

export const Plus = () => {
  return (
    <section className="plus">
      <div className="container">
        <h3 className="plus-title">
          Ventajas de la tienda <br /> Farmacias Guadalajara
        </h3>
        <ul className="list-reset plus-list">
          <li className="plus-item">
            <div className="plus-img">
              <img src="/image/plus1.png" alt="" />
            </div>
            <div className="plus-text">
              Asesoramiento <br /> individual sobre cada <br /> producto
            </div>
          </li>
          <li className="plus-item">
            <div className="plus-img">
              <img src="/image/plus2.png" alt="" />
            </div>
            <div className="plus-text">
              Pago en el momento <br /> de la compra
            </div>
          </li>
          <li className="plus-item">
            <div className="plus-img">
              <img src="/image/plus3.png" alt="" />
            </div>
            <div className="plus-text">
              Rapidez de <br /> entrega
            </div>
          </li>
          <li className="plus-item">
            <div className="plus-img">
              <img src="/image/plus4.png" alt="" />
            </div>
            <div className="plus-text">
              Una amplia gama <br /> de vitaminas
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};
