import { Link } from "react-router-dom";
import { SearchBox } from "../Search";
import "./style.scss";
import { routeNames } from "../../navigation/routeNames";
import { HeadOne } from "../Search/components/HeadOne";
export const Hero = () => {
  return (
    <section className="hero">
      <div className="container">
        <HeadOne />
        <div className="line"></div>
        <div className="link-box">
          <Link to={routeNames.contacts}>Contacto</Link>
          <Link to={routeNames.order}>Cómo hacer un pedido</Link>
        </div>
        <SearchBox />
      </div>
    </section>
  );
};
